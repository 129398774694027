// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>

console.log('Vite ⚡️ Rails')
import "@hotwired/turbo-rails";
document.addEventListener("turbo:load", () => {
  console.log("Turbo loaded!");
});

import "../controllers";
import "../channels";